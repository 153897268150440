<template>
  <a-spin :spinning="spinning">
    <!-- <react-table
      :table-data="tableData"
      :total-items="totalItems"
      :showTotal="showTotalFun"
      :columns="columns"
      :scroll="{ x: 2200 }"
      :actions-type="actionsType"
      :is-full="true"
      :current-page="pageNumber"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
      @emitSortData="updateSortData"
    > -->
    <ele-table
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :is-full="true"
      :actionsType="actionsType"
      :current-page="pageNumber"
      :hideRowSelection="true"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
      <a-row :gutter="24">
        <a-col class="gutter-row" :span="4">
          <a-input
            v-model.trim="searchDto.batchNo"
            :placeholder="l('BatchNo')"
            style="width: 100%"
            allowClear
          ></a-input>
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-month-picker
            v-model="searchDto.yearMonth"
            valueFormat="YYYY-MM"
            :placeholder="l('SalaryMonth')"
            style="width: 100%"
          />
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-select
            allowClear
            v-model="searchDto.status"
            style="width: 100%"
            :placeholder="l('发放状态')"
          >
            <a-select-option
              v-for="(item, index) in SalaryStatus"
              :key="index"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-select
            v-model="searchDto.customerId"
            style="width: 100%"
            show-search
            :filter-option="filterOption"
            :placeholder="l('PleaseSelectCustomer')"
            allowClear
          >
            <a-select-option
              v-for="item in Customers"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-select
            v-model="searchDto.payCompanyId"
            style="width: 100%"
            show-search
            :filter-option="filterOption"
            :placeholder="l('PlaceSelectPayCompany')"
            allowClear
          >
            <a-select-option
              v-for="item in PayCompanys"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-input-number
            v-model.trim="searchDto.salaryTotal"
            placeholder="发放总金额"
            style="width: 100%"
            :min="0"
            :step="0.01"
          />
        </a-col>
        <a-col class="gutter-row" :span="6">
          <a-range-picker
            v-model="searchDto.BatchTime"
            :show-time="{ format: 'HH:mm' }"
            :placeholder="['开始时间', '结束时间']"
            valueFormat="YYYY-MM-DD HH:mm"
            style="width: 100%"
            @change="onChange1"
            @ok="onOk"
          />
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-select
            v-model="searchDto.isWithdraw"
            style="width: 98%"
            :placeholder="l('是否提现记录')"
            allowClear
          >
            <a-select-option value="true">提现记录</a-select-option>
            <a-select-option value="false">非提现记录</a-select-option>
          </a-select>
        </a-col>
        <a-col class="gutter-row" :span="5">
          <a-button
            type="primary"
            @click="search"
            v-if="isGranted('Pages.SalaryPermissions.Query')"
          >
            {{ l("Search") }}
          </a-button>
          <a-button @click="clearAndGetData"> {{ l("Reset") }}</a-button>
          <!-- <a-button @click="showSearchDrawer">高级搜索</a-button> -->
        </a-col>
      </a-row>
    </ele-table>

    <a-modal
      :title="l('HintInformation')"
      :visible="showSettlementInfo"
      @cancel="hideSettlementInfo"
      :width="805"
    >
      <a-row :gutter="24">
        <a-col span="24">
          <a-table
            bordered
            :data-source="settlementErrorResult"
            :pagination="false"
          >
            <a-table-column
              key="realName"
              :title="l('NameSurname')"
              data-index="realName"
              :ellipsis="true"
              width="75px"
            ></a-table-column>
            <a-table-column
              key="phoneNumber"
              :title="l('ProjectUserPhone')"
              data-index="phoneNumber"
              :ellipsis="true"
              width="150px"
            ></a-table-column>
            <a-table-column
              key="idCard"
              :title="l('ProjectUserIdCard')"
              data-index="idCard"
              :ellipsis="true"
              width="190px"
            ></a-table-column>
            <a-table-column
              key="thisTimeAmount"
              :title="l('SettlementAmount')"
              data-index="thisTimeAmount"
              :ellipsis="true"
              width="90px"
            ></a-table-column>
            <a-table-column
              key="remarks"
              :title="l('MessageError')"
              data-index="remarks"
              :ellipsis="true"
              width="250px"
            ></a-table-column>
          </a-table>
        </a-col>
      </a-row>
      <template slot="footer">
        <a-button key="back" @click="hideSettlementInfo">{{
          l("Close")
        }}</a-button>
      </template>
    </a-modal>

    <a-modal
      :title="l('BatchInvilad')"
      :visible="showBatchReject"
      @cancel="hideBatchReject"
    >
      <a-row :gutter="24">
        <a-col span="6"><i style="color: red">*</i>{{ l("reason") }}:</a-col>
        <a-col span="18">
          <a-textarea
            v-model="SalaryRejectReason"
            style="width: 98%"
            :rows="5"
          ></a-textarea>
        </a-col>
      </a-row>
      <template slot="footer">
        <a-button
          key="submit"
          type="primary"
          :disabled="spinning"
          @click="submitReject()"
        >
          {{ l("NotPass") }}
        </a-button>
        <a-button key="back" @click="hideBatchReject">
          {{ l("Close") }}
        </a-button>
      </template>
    </a-modal>

    <a-modal
      centered
      :title="l('SalarySendSure')"
      :visible="submitSalaryModal.visible"
      :confirm-loading="submitSalaryModal.confirmLoading"
      :maskClosable="submitSalaryModal.maskClosable"
      :destroyOnClose="submitSalaryModal.destroyOnClose"
      :width="submitSalaryModal.width"
      @ok="submitSalaryModal.confirm"
      @cancel="submitSalaryModal.cancel"
      :cancelText="submitSalaryModal.cancelText"
      :okText="submitSalaryModal.okText"
    >
      <div style="margin-left: 20px;" v-if="fileList.length>0">
        <a-form-item label="垫付证明">
          <a-upload
            :action="uploadUrl"
            :multiple="true"
            :data="{ subdir: this.subdirName }"
            :file-list="fileList"
            :showUploadList="{
              showPreviewIcon: true,
              showRemoveIcon: !isDisabled,
            }"
            list-type="picture-card"
          >
            <a-button :disabled="isDisabled" v-if="!isDisabled">
              <a-icon type="upload" /> 垫付证明
            </a-button>
          </a-upload>
        </a-form-item>
      </div>
      <div style="text-align: center">
        是否确认通过
        <a-input
          style="width: 250px"
          v-model="submitSalaryModal.remark"
          allowClear
        />
        的审批发放
      </div>
    </a-modal>
  </a-spin>
</template>
<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import moment from "moment";
// import ReactTable from "@/components/react-table";
import EleTable from "@/components/ele-table";
import SalaryDetail from "@/components/salary/salarydetail.vue";
import { CommonServiceProxy } from "@/shared/common-service";
import { fileDownloadService } from "@/shared/utils";
import { AppConsts } from "@/abpPro/AppConsts";
import { environment } from "@/environments/environment";

export default {
  name: "settlementModule",
  mixins: [AppComponentBase],
  components: { EleTable },
  data: function () {
    return {
      spinning: false,
      commonService: null,
      searchDto: {
        batchNo: "",
        salaryTotal: null,
        yearMonth: "",
        BatchTime: "",
        startTime: "",
        endTime: "",
        status: undefined,
        customerId: undefined,
        payCompanyId: undefined,
        sorting: "",
        skipCount: 0,
        maxResultCount: 50,
        isWithdraw: undefined,
      },
      totalItems: 0,
      pageNumber: 1,
      // 共多少页
      // 条数显示范围
      pagerange: [1, 1],
      totalPages: 1,
      selectedRowKeys: [],
      selectedRows: [],
      tableData: [],
      SalaryStatus: [
        // { label: this.l("待处理"), value: 0 },
        { label: this.l("待审核"), value: 1 },
        { label: this.l("已作废"), value: 2 },
        { label: this.l("发放中"), value: 3 },
        { label: this.l("已驳回"), value: 4 },
        { label: this.l("已发放"), value: 5 },
        { label: this.l("部分发放"), value: 6 },

        // { label: this.l("全部失败"), value: -1 },
        // // { label: this.l("WaitForSalary"), value: 0 },
        // { label: this.l("WaitForApply"), value: 1 },
        // // { label: this.l("SalaryInvalid"), value: 2 },
        // { label: this.l("SalaryPending"), value: 3 },
        // { label: this.l("NotPass"), value: 4 },
        // { label: this.l("SalarySended"), value: 5 },
        // { label: this.l("部分发放"), value: 6 },
      ],
      columns: [],
      PayCompanys: [], // { value: "", label: this.l("PlaceSelectPayCompany") }
      Customers: [], // { value: "", label: this.l("PleaseSelectCustomer") }
      actionsType: [],
      showBatchReject: false,
      currentSalaryData: null,
      SalaryRejectReason: "",
      SalaryRemark: "",
      InvoiceTitles: [], // { value: "", label: this.l("PleaseSelect") }
      showSettlementInfo: false,
      settlementErrorResult: [],
      // 点击发放弹出的modal
      submitSalaryModal: {
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: false,
        visible: false,
        width: 500,
        title: "",
        remark: "",
        cancelText: "取消",
        okText: "确认",
        form: {},
        confirm: () => {},
        cancel: () => {
          this.submitSalaryModal.confirm = () => {};
          this.submitSalaryModal.confirmLoading = false;
          this.submitSalaryModal.visible = false;
        },
      },
      // 上传文件
      fileList: [],
      uploadUrl: "",
      subdirName: "",
      isDisabled: true,
    };
  },
  methods: {
    onChange1(value, dateString) {
      this.searchDto.startTime = "";
      this.searchDto.endTime = "";
      console.log("Selected Time: ", value);
      console.log("Formatted Selected Time: ", dateString);
    },
    onOk(value) {
      console.log("onOk: ", value);
      this.searchDto.startTime = value[0];
      this.searchDto.endTime = value[1];
    },
    initColumns() {
      let _this = this;
      this.columns = [
        {
          title: this.l("BatchNo"),
          dataIndex: "batchNo",
          width: 220,
          fixed: "left",
          scopedSlots: { customRender: "batchNo" },
        },
        {
          title: this.l("Status"),
          dataIndex: "statusName",
          fixed: "left",
          type: "tag",
          // width: 100,
          customRender: function (text, record, index) {
            let child;
            if (record.status == 1) {
              child = _this.$createElement("div", {
                domProps: {
                  innerHTML: record.statusName,
                },
                attrs: {},
                style: { color: "black", backgroundColor: "#d3f261" },
                on: {
                  click: function () {},
                },
              });
            } else {
              child = text;
            }

            let obj = {
              children: child,
              attrs: {},
              on: {},
            };
            return obj;
          },
          scopedSlots: { customRender: "statusName" },
        },
        {
          title: this.l("ProjectHeaderCustomerName"),
          dataIndex: "customerName",
          width: 220,
        },
        {
          title: this.l("PayCompany"),
          dataIndex: "companyName",
          width: 220,
        },
        {
          title: this.l("InvoiceTitle"),
          dataIndex: "invoiceTitle",
          width: 200,
        },
        {
          title: this.l("OrderDate"),
          dataIndex: "creationTime",
          sorter: true,
          width: 180,
          customRender: (text, record, index) => {
            var html = text ? text.replace("T", " ") : "";
            if (html.length > 18) {
              html = html.substring(0, 19);
            }
            return html;
          },
        },
        {
          title: this.l("SalaryTotalAmount"),
          dataIndex: "salaryTotal",
          width: 120,
        },
        {
          title: this.l("SalaryCount"),
          dataIndex: "salaryCount",
          width: 120,
        },
        {
          title: this.l("SuccessCount"),
          dataIndex: "success",
          width: 120,
        },
        {
          title: this.l("FailedCount"),
          dataIndex: "failed",
          // width: 100,
        },
        {
          title: this.l("ProcessingCount"),
          dataIndex: "pending",
          // width: 120,
        },
        {
          title: this.l("ReSalaryCount"),
          dataIndex: "resalary",
          // width: 160,
        },
        {
          title: this.l("是否提现记录"),
          dataIndex: "withdrawal",
          // width: 200,
          customRender: (text, record, index) => {
            if (record.isWithdraw === true) {
              return "是";
            }
            return "否";
          },
        },
        {
          title: this.l("reason"),
          dataIndex: "rejectReason",
          // width: 200,
          customRender: (text, record, index) => {
            switch (record.status) {
              case 0: //待处理
              case 1: //待审核
              case 3: //发放中
              case 5: //已发放
              case 6: //部分发放
                return "";
              case 2: //已作废
                return record.invalidReason;
              case 4: //已驳回
                return record.rejectReason;
            }
            return record.failedReason;
          },
        },
        {
          title: this.l("ProjectRemark"),
          dataIndex: "remark",
          width: 200,
          align: "center",
        },
        {
          title: this.l("Action"),
          dataIndex: "actions",
          align: "center",
          fixed: "right",
          width: 100,
          scopedSlots: { customRender: "actions" },
        },
      ];
    },
    /**
     * ������
     * @param input
     * @param option
     */
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    /**
     * ��ʼ��������*/
    initActionType() {
      let _this = this;
      let obj = {
        type: "sign-manage",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: this.isGranted("Pages.SalaryPermissions.Provider"),
              name: this.l("BatchNo").substring(0, 2),
              icon: "plus",
              fn: (data) => {
                if (data.status != 1) {
                  abp.message.warn(this.l("SalaryApplyError"));
                  return false;
                }
                _this.submitSalaryModal.remark = data.remark;
                _this.submitSalaryModal.visible = true;
                this.fileList = [];
                if (data.paymentUrls && data.paymentUrls.indexOf(",") !== -1) {
                  let arr = data.paymentUrls.split(",");
                  arr.map((item, i) => {
                    let n = item.split("/");
                    _this.fileList.push({
                      uid: `-${i + 1}`,
                      url: `${AppConsts.uploadBaseUrl}${item}`,
                      name: n[n.length - 1],
                      response: { data: { Path: item } },
                      status: "done",
                    });
                  });
                } else if (
                  data.paymentUrls &&
                  data.paymentUrls !== "undefined"
                ) {
                  let n = data.paymentUrls.split("/");
                  _this.fileList.push({
                    uid: `-1`,
                    url: `${AppConsts.uploadBaseUrl}${data.paymentUrls}`,
                    name: n[n.length - 1],
                    response: { data: { Path: data.paymentUrls } },
                    status: "done",
                  });
                }
                _this.submitSalaryModal.confirm = async function () {
                  _this.submitSalaryModal.confirmLoading = true;
                  _this.currentSalaryData = data;
                  _this.submitSalary();
                };
              },
            },
            {
              granted: this.isGranted("Pages.SalaryPermissions.Reject"),
              name: this.l("NotPass"),
              icon: "close",
              fn: (data) => {
                if (data.status != 1) {
                  abp.message.warn(this.l("SalaryRejectError"));
                  return false;
                }
                //����
                this.currentSalaryData = data;
                this.showBatchReject = true;
                this.SalaryRejectReason = "";
              },
            },
            {
              granted: this.isGranted("Pages.SalaryPermissions.Detail"),
              name: this.l("SalaryDetail"),
              icon: "ordered-list",
              fn: (data) => {
                ModalHelper.create(
                  SalaryDetail,
                  { id: data.id },
                  {
                    isChange: true,
                    width: "1200px",
                  }
                );
              },
            },
            {
              granted: this.isGranted("Pages.SalaryPermissions.RefundExport"),
              name: this.l("退票导出"),
              icon: "export",
              fn: (data) => {
                let message = "确认要导出发放明细数据吗？";
                abp.message.confirm(message, this.l("提示"), (res) => {
                  if (res) {
                    _this._exportSalaryBatch(data.id);
                  }
                });
              },
            },
          ],
        },
      };
      this.actionsType = obj;
    },
    async _exportSalaryBatch(id) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Salary/ExportSalaryBatch",
          params: {
            id: id,
          },
        });
        fileDownloadService.downloadTempFile(res);
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    /**
     * ��ȡ���з��Ź�˾
     * */
    getAllPayCompany() {
      // this.spinning = true;
      this.$api
        .request({
          url: this.$apiUrl + "/api/services/app/Organization/GetAllSelectList",
          method: "GET",
        })
        .then((res) => {
          this.PayCompanys.splice(0, this.PayCompanys.length);
          if (res && res.data) {
            this.PayCompanys = res.data.map((item) => {
              return {
                value: item.value,
                label: item.text,
              };
            });
          }
        })
        .catch((e) => {
          abp.message.error(this.l("Error500Desc"));
          console.error(e);
        })
        .finally(() => {
          // this.spinning = false;
        });
    },
    /**
     * ��ȡ�ͻ�
     * */
    getAllCutomer() {
      let options = {
        url: this.$apiUrl + "/api/services/app/Customer/GetCustomerOption",
        method: "GET",
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            this.Customers.splice(1, this.Customers.length - 1);
            if (res.data) {
              for (let i = 0; i < res.data.length; i++) {
                this.Customers.push(res.data[i]);
              }
            }
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    /**
     * ��ȡ�ͻ���Ʊ̧ͷ
     * @param name �ͻ�����
     */
    getInvoiceTitle(name) {
      // this.InvoiceTitles.splice(1, this.InvoiceTitles.length);
      let customers = this.Customers.filter((p) => p.label == name);
      if (customers && customers.length > 0) {
        this.spinning = true;
        let customerId = customers[0].value;
        let options = {
          url:
            this.$apiUrl +
            "/api/services/app/Customer/GetInvoiceTitleSelectList",
          method: "GET",
          params: { customerId: customerId },
        };
        this.$api
          .request(options)
          .then((res) => {
            if (res.status == 200) {
              for (let i = 0; i < res.data.length; i++) {
                let item = res.data[i];
                this.InvoiceTitles.push({
                  value: item.value,
                  label: item.text,
                });
              }
            }
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            this.spinning = false;
          });
      }
    },
    /**
     * ���ͨ��
     */
    submitSalary() {
      var remark = this.submitSalaryModal.remark;
      if (!remark) {
        abp.message.warn("请填写备注");
        this.submitSalaryModal.confirmLoading = false;
        return false;
      }
      this.spinning = true;
      let options = {
        url: this.$apiUrl + "/api/services/app/Salary/SetSalaryAuditPassed",
        method: "POST",
        data: {
          salaryBatchId: this.currentSalaryData.id,
          remark: this.submitSalaryModal.remark,
        },
      };
      this.$api
        .request(options)
        .then((res) => {
          let data = res.data;
          if (data && data.length > 0) {
            this.showSettlementInfo = true;
            this.settlementErrorResult.splice(
              0,
              this.settlementErrorResult.length
            );
            this.settlementErrorResult = data;
          } else {
            abp.message.success(this.l("SuccessfullyOperation"));
          }
          this.getData();
        })
        .catch((e) => {
          abp.message.error(this.l("Error500Desc"));
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
          this.submitSalaryModal.visible = false;
          this.submitSalaryModal.confirmLoading = false;
        });
    },
    /**
     * ����
     */
    submitReject() {
      if (this.SalaryRejectReason == "") {
        abp.message.warn("请填写驳回原因");
        return false;
      }
      this.spinning = true;
      let options = {
        url: this.$apiUrl + "/api/services/app/Salary/SetSalaryAuditRejected",
        method: "POST",
        data: {
          salaryBatchId: this.currentSalaryData.id,
          reason: this.SalaryRejectReason,
        },
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            abp.message.success(this.l("SuccessfullyOperation"));
            this.getData();
            this.hideBatchReject();
          }
        })
        .catch((e) => {
          console.error(e);
          abp.message.error(this.l("Error500Desc"));
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    search() {
      this.pageNumber = 1;
      this.searchDto.skipCount = 0;
      this.getData();
    },
    getData() {
      if (this.searchDto.ProjectId == "") {
        return false;
      }
      this.spinning = true;
      let options = {
        url: this.$apiUrl + "/api/services/app/Salary/GetSalaryPaged",
        method: "GET",
        params: {
          ...this.searchDto,
          isFinance: true,
          status:
            this.searchDto.status == undefined ? null : this.searchDto.status,
          customerId:
            this.searchDto.customerId == "" ? null : this.searchDto.customerId,
          companyId:
            this.searchDto.payCompanyId == ""
              ? null
              : this.searchDto.payCompanyId,
          sorting: this.searchDto.sorting == "" ? null : this.searchDto.sorting,
          isWithdraw: this.searchDto.isWithdraw,
        },
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            let data = res.data;
            this.totalPages = Math.ceil(
              data.totalCount / this.searchDto.maxResultCount
            );
            this.totalItems = data.totalCount;
            this.pagerange = [
              (this.pageNumber - 1) * this.searchDto.maxResultCount + 1,
              this.pageNumber * this.searchDto.maxResultCount,
            ];
            this.tableData = data.items.map((item) => {
              let counts = item.salaryPayStatusCounts;
              let success = 0,
                failed = 0,
                pending = 0,
                resalary = 0;
              if (counts && counts.length > 0) {
                let sus = counts.filter((it) => it.status == 1);
                success = sus && sus.length > 0 ? sus[0].count : 0;
                let fail = counts.filter((it) => it.status == 2);
                failed = fail && fail.length > 0 ? fail[0].count : 0;
                let re = counts.filter((it) => it.status == 3);
                resalary = re && re.length > 0 ? re[0].count : 0;
                let pe = counts.filter((it) => it.status == 5);
                // pending = item.salaryCount - success - failed;
                pending = pe && pe.length > 0 ? pe[0].count : 0;
              }
              return {
                ...item,
                success: success,
                failed: failed,
                pending: pending,
                resalary: resalary,
                creationTime: moment(item.creationTime).format(
                  "YYYY-MM-DD HH:mm:ss"
                ),
                statusNameColor: item.statusName === "待审核" ? "#87d068" : "",
              };
            });
            this.spinning = false;
          }
        })
        .catch((e) => {
          abp.message.error(this.l("Error500Desc"));
          console.error(e);
          this.spinning = false;
        })
        .finally(() => {
          // this.spinning = false;
        });
    },
    clearFilterAndRefresh() {
      this.searchDto.skipCount = 0;
      this.getData();
    },
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.showSizeChange(current, size);
    },
    showSizeChange(current, size) {
      this.pageNumber = 1;
      this.searchDto.skipCount = 0;
      this.searchDto.maxResultCount = size;
      this.getData();
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.searchDto.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    updatePageChange(newV) {
      let { page, pageSize, skipCount } = newV;
      this.pageNumber = page;
      this.searchDto.skipCount = (page - 1) * pageSize;
      this.searchDto.maxResultCount = pageSize;
      this.getData();
    },
    hideBatchReject() {
      this.showBatchReject = false;
    },
    clearAndGetData() {
      this.searchDto.startTime = "";
      this.searchDto.endTime = "";
      this.searchDto.batchNo = "";
      this.searchDto.salaryTotal = null;
      this.searchDto.yearMonth = "";
      this.searchDto.BatchTime = "";
      this.searchDto.status = undefined;
      this.searchDto.customerId = undefined;
      this.searchDto.payCompanyId = undefined;
      this.searchDto.isWithdraw = undefined;
      this.getData();
    },
    hideSettlementInfo() {
      this.showSettlementInfo = false;
    },
    initCurMonth() {
      let d = new Date();
      let y = d.getFullYear();
      let m = d.getMonth() + 1;
      let _m = m < 10 ? `0${m}` : m;
      this.searchDto.yearMonth = `${y}-${_m}`;
    },
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.initCurMonth();
    this.getAllPayCompany();
    this.getAllCutomer();
    this.initActionType();
    this.initColumns();
    this.uploadUrl = AppConsts.uploadBaseUrl + "/API/Upload";
    this.subdirName = environment.production ? "abpProd" : "abpDev";
  },
  mounted() {
    this.getData();
    let self = this;
    document.onkeydown = function (e) {
      let ev = document.all ? window.event : e;
      if (ev.keyCode === 13) {
        self.getData();
      }
    };
  },
};
</script>
<style lang="less" scoped>
.btn--container {
  margin-top: 20px;
}

.btn--footer {
  margin-bottom: 20px;
}
.gutter-row {
  padding: 0 0 10px 5px;
}
</style>
